import * as React from "react"

import Ecoregions from "../images/saskInsets/Ecoregions.png"
import Elevation from "../images/saskInsets/Elevation.png"
import Indigenous from "../images/saskInsets/Indigenous.png"
import Density from "../images/saskInsets/Density.png"
import Precipitation from "../images/saskInsets/Precipitation.png"
import Temperature from "../images/saskInsets/Temperature.png"

export default function InsetGallery() {

    return(
        <div id="saskInsetGallery">
            <img src={Density} alt="Small inset map showing the population density of Saskatchewan" />            
            <img src={Precipitation} alt="Small inset map showing the mean annual precipitation of Saskatchewan" />
            <img src={Ecoregions} alt="Small inset map showing the ecoregions of Saskatchewan" />
            <img src={Elevation} alt="Small inset map showing the elevation of Saskatchewan" />
            <img src={Temperature} alt="Small inset map showing the mean annual temperature of Saskatchewan" />
            <img src={Indigenous} alt="Small inset map showing the Indigenous population of Saskatchewan" />
        </div>
    )

}