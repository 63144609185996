import * as React from "react"

import layer_0 from "../saskpanel/layer_0.png"
import layer_1 from "../saskpanel/layer_1.png"
import layer_2 from "../saskpanel/layer_2.png"
import layer_3 from "../saskpanel/layer_3.png"
import layer_4 from "../saskpanel/layer_4.png"
import layer_5 from "../saskpanel/layer_5.png"
import layer_6 from "../saskpanel/layer_6.png"

export default function SaskPanel() {

    return(
        <div className="saskPanel">
            <img src={layer_0} alt="An animated, multi-layered view of all the details in Alex's wall map."/>
            <img src={layer_1} alt=""/>
            <img src={layer_2} alt=""/>
            <img src={layer_3} alt="" style={{mixBlendMode: "multiply"}}/>
            <img src={layer_4} alt=""/>
            <img src={layer_5} alt=""/>
            <img src={layer_6} alt="" style={{mixBlendMode: "multiply"}}/>
        </div>
    )

}