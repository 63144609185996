import * as React from "react";
import Layout from "../components/layout";
import SaskPanel from "../components/SaskPanel";
import InsetGallery from "../components/InsetGallery";

import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import sasktel from "../images/sasktel.svg";

import Helmet from "react-helmet";

export default function Index() {
  return (
    <Layout>
        <Helmet>
        <meta charSet="utf-8" />
        <title>McPhee's Saskatchewan</title>
        <meta name="description" content="Saskatchewan's only independent historical wall map. Now in its 2nd edition!"/>
        <meta name="image" content="https://awmcphee.ca/regina.jpg" />
        <meta property="og:image" content="https://awmcphee.ca/regina.jpg"/>
        <meta name="twitter:card" content="summary"/>
        <meta name="twitter:site" content="@ksituan"/>
        <meta name="twitter:title" content="McPhee's Saskatchewan"/>
        <meta name="twitter:description" content="Saskatchewan's only independent historical wall map. Now in its 2nd edition!"/>
        <meta name="twitter:image" content="https://awmcphee.ca/regina.jpg"/>
      </Helmet>
      <h1>McPhee's Saskatchewan, 2nd edition</h1>
      <h2>The independently published wall map that shows prairie history at a glance</h2>
      <a className="mapLink bigSask" href="/saskatchewan.jpg" target="_blank" rel="noopener noreferrer">
        <StaticImage className="map linkedMap" src="../images/saskatchewan.jpg" alt="60dpi preview of map"/>
        <div className="mapNote width">← 36 inches wide →</div>
        <div className="mapNote height">← 66 inches tall →</div>
        <div className="mapNote note">Click map to open preview in new tab. (4.90MB)</div>
      </a>
      <h3>An independent wall map?</h3>
      <div className="twoColumns">
      <div className="tvContainer">
      <a className="seenonTV" href="https://www.youtube.com/watch?v=AeKIwNWQcqI" target="_blank" rel="noopener noreferrer">
        <p>As seen on</p>
        <img src={sasktel} alt="SaskTel" className="sasktel" />
      </a>
      <p className="mapNote note">Check out my cable TV special! (10:06)</p>
      </div>
      <div>
      <p>I design from scratch, I print, and I publish in Val Marie, Saskatchewan. In an industry full of phone apps and American tech giants, my job is bringing prairie stories home.</p>
      <p>Over 1,000 hours of data collection and manual drafting have gone into this <u>large</u> 36" x 66" product. The final result combines space-age precision with timeless craft, and can be found hanging in over 250 Sask. communities.</p>
      <p><strong>You asked for it</strong> - my first printing sold out in its first year! I've added the public's most-requested feature, fixed a handful of embarrassing mistakes, and created a 10-in-1 <strong>Second Edition</strong> with all-new insets expounding upon Saskatchewan's population, climate, and landscape.</p>
      </div>
      </div>
      <InsetGallery/>
      <p>Check the upper-right corner! I've always loved browsing old school atlases, and this Second Edition exclusive feature is inspired by traditional print.
        These colourful insets cover a variety of interesting topics that just wouldn't fit into the rest of the map, selected for educational value and curriculum compatibility.</p>
      <SaskPanel/>
      <p>Dozens of data layers show <u>more</u> than any other print map, including every grain elevator, every grid road, every Hutterite colony, every TLE parcel and reserve surrender...</p>
      <StaticImage
        className="map"
        src="../images/moosejaw.jpg"
        alt="An excerpt of the wall map showing Moose Jaw and the Missouri Coteau."
      />
      <p>Saskatchewan's majestic glacial landscapes aren't really all that flat. See their rise and fall illuminated in dramatic 3-D by my own in-house relief-shading algorithm.</p>
      <StaticImage
        className="map"
        src="../images/watrous.jpg"
        alt="An excerpt of the wall map showing Watrous and the Quill Lakes."
      />
      <p>It's all about the details, and you'll find plenty in my work. Not commonly seen on other maps: Treaty boundaries, irrigation canals, airfields, the flooded Quill Lakes, community pastures... and even the province's mean centre of population (accurate to 2021).</p>
      <StaticImage
        className="map"
        src="../images/uranium.jpg"
        alt="An excerpt of the wall map showing Uranium City and its surrounding mines."
      />
      <p>Don't forget the other half of the province! No existing dataset was good enough to properly illustrate the complex Canadian Shield: every creek on the map is traced by hand. Explore the great North with a comprehensive listing of mines, lodges, and lands.</p>
    <h3>Ready to go?</h3>
    <p><em>Saskatchewan</em> retails at $79.99: that's original artwork plus sturdy plastic lamination. Or, save $20 by choosing an old stock <u>un</u>laminated First Edition, on a fine 100lb gloss. The map will arrive in a cardboard tube - no folds - and is Canadian printed.</p>
    <p>Get one at my online checkout here:</p>
    <Link className="buySticker" to="/store">
      <StaticImage className="floatimg" src="../images/sticker.png" alt="" />
      <div className="stickerLabel">Buy now</div>
    </Link>
    </Layout>
  )
}